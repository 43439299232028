import {Inject, Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {UserService} from "./user.service";
import {merge, of, switchMap, tap} from "rxjs";
import {distinctUntilChanged, filter, shareReplay} from "rxjs/operators";
import tinycolor from "tinycolor2";
import {API_BASE_URL, API_VERSION} from "../shared.module";
import {AuthService} from "./auth.service";

export class CustomisationModel {
  address?: string;
  android_application_link?: string;
  background_file_url?: string;
  company_id?: number;
  email?: string;
  facebook?: string;
  favicon_url?: string;
  ios_application_link?: string;
  name?: string;
  nav_logo_file_url?: string;
  phone_number?: string;
  twitter?: string;
  website?: string;
  welcome_message?: string;
  login_register_message?: string;
  pad_agreement?: {
    id: number,
    body: string,
    revision_date: string
  };
  tenant_dashboard?: {title: string, enabled: boolean}[];
  payment_page_message?: string;
  global_welcome_message?: string;

  constructor(
    public primaryColor: string,
    public secondaryColor: string,
    public logoUrl: string,
    public priority: number,
    args?: any
  ) {
    // noinspection TypeScriptValidateTypes
    Object.assign(this, args || {});
  }
  setUpPalette() {
    const primary = this.generatePalette('primary', this.primaryColor);
    const secondary = this.generatePalette('secondary', this.secondaryColor);
    this.writeProps(primary);
    this.writeProps(secondary);
  }

  setUpFavicon() {
    if(this.favicon_url) {
      document.getElementById('favicon_dynamic')?.remove();
      const fav = document.createElement('link');
      fav.setAttribute('id', 'favicon_dynamic');
      fav.setAttribute('rel', 'icon');
      fav.setAttribute('type', 'image/x-icon');
      fav.setAttribute('href', this.favicon_url);
      document.getElementsByTagName('head')[0]?.appendChild(fav);
    }
  }

  writeProps(palette: Colour[]) {
    palette.forEach(
      (c) => {
        document.documentElement.style.setProperty(c.name, c.hex)
      }
    )
  }

  generatePalette(name: string, colour: string): Colour[] {
    return [
      new Colour(`__${name}`, colour),
      new Colour(`--${name}-1`, colour),
      new Colour(`__${name}-2`, tinycolor(colour).lighten(7).toHex()),

      new Colour(`--${name}-t-7`, tinycolor(colour).setAlpha(0.7).toRgbString()),

      new Colour(`--${name}-50`, tinycolor(colour).lighten(80).toHex()),
      new Colour(`--${name}-100`, tinycolor(colour).lighten(60).toHex()),
      new Colour(`--${name}-200`, tinycolor(colour).lighten(50).toHex()),
      new Colour(`--${name}-300`, tinycolor(colour).lighten(40).toHex()),
      new Colour(`--${name}-400`, tinycolor(colour).lighten(30).toHex()),
      new Colour(`--${name}-500`, tinycolor(colour).lighten(20).toHex()),
      new Colour(`--${name}-600`, tinycolor(colour).lighten(10).toHex()),
      // new Colour(`--${name}-700`, tinycolor(colour).toHex()), // set up throw var(__primary)
      new Colour(`--${name}-800`, tinycolor(colour).darken(7).toHex()),
      new Colour(`--${name}-900`, tinycolor(colour).darken(14).toHex()),
    ];
  }
}

export class Colour {
  constructor(
    public name: string,
    public hex: string
  ) {
    this.hex = hex.startsWith('#') || hex.startsWith('rgb') ? hex : '#' + hex;
  }
}

@Injectable({
  providedIn: "root"
})
export class CustomisationService {
  config = merge(
    this.http.get(
      this.baseUrl + this.version + '/branding'
    ).pipe(
      switchMap((r: any) => {
        let data = r;
        if(r.data) {
          data = r.data;
        }
        return of(new CustomisationModel(data.primary_colour, data.secondary_colour, data.logo_file_url, 0, data));
      }),
      shareReplay(),
    ),
    this.authService.isLoggedIn.pipe(
      filter((l) => l),
      switchMap(() => {
        return this.userService.me().pipe(
          switchMap(u => {
            const data = u.branding;
            return of(new CustomisationModel(data.primary_colour, data.secondary_colour, data.logo_file_url, 1, data))
          })
        )
      })
    )
  ).pipe(
    distinctUntilChanged((prev, curr) => {
      return curr.priority < prev.priority;
    }),
    tap(
      (config: CustomisationModel) => {
        config.setUpPalette();
        config.setUpFavicon();
      }
    ),
    shareReplay()
  );

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private authService: AuthService,
    @Inject(API_BASE_URL) private baseUrl: string,
    @Inject(API_VERSION) private version: string
  ) { }
}
