import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {PaymentHistoryModel} from "../resources/payment-history.model";
import { map } from "rxjs/operators";
import moment from "moment";

export interface PaymentHistoryFilter {
  search?: string;
  status?: string;
  payment_method_id?: number;
  amount_from?: number;
  amount_to?: number;
  start_date?: Date | string;
  end_at?: Date | string;
}

@Injectable({
  providedIn: "root"
})
export class PaymentHistoryFilterService {

  /**
   *  <_value> is a BehaviorSubject that manages the state of filter criteria for payment history.
   *  It stores the filter criteria as a dictionary, where each key corresponds to a specific
   *  attribute of the payment history data (e.g., `status`, `amount_from`, `amount_to`, `start_date`, etc.), and
   *  the value specifies the condition that the attribute must satisfy.
   */
  protected _value = new BehaviorSubject<PaymentHistoryFilter>({});

  constructor() {}

  setFilter(filter: PaymentHistoryFilter) {
    this._value.next(filter);
  }

  updateFilter(filter: PaymentHistoryFilter) {
    this._value.next({
      ...this._value.getValue(), 
      ...filter
    });
  }

  getFilter(): BehaviorSubject<PaymentHistoryFilter> {
    return this._value;
  }

  isEmpty(): Observable<boolean> {
    return this._value.pipe(
      map(filter => {
        return Object.keys(filter).length === 0 || Object.values(filter).every(value => !value);
      })
    );
  }  

  filter(data: PaymentHistoryModel[]): PaymentHistoryModel[] {
    const filter = this.getFilter().getValue();
    if(filter.search) {
      const searchStr = filter.search?.toString().toLowerCase() ?? '';
      data = data.filter(
        i => i.description?.toLowerCase().includes(searchStr)
        || i.total_amount?.toString().includes(searchStr)
        || (Number(i.total_amount) + Number(i.tenant_fee))?.toString().includes(searchStr)
        || i.id?.toString().includes(searchStr)
      );
    }
    if(filter.status) {
      data = data.filter(i => i.status === filter.status);
    }
    if(filter.payment_method_id) {
      data = data.filter(i => i.payment_method?.id === Number(filter.payment_method_id));
    }
    if(filter.amount_from) {
      data = data.filter(i => (i.total_amount||0) > Number(filter.amount_from));
    }
    if(filter.amount_to) {
      data = data.filter(i => (i.total_amount||0) < Number(filter.amount_to));
    }
    if(filter.start_date) {
      data = data.filter(i => moment(i.created_at).isSameOrAfter(moment(filter.start_date)));
    }
    if(filter.end_at) {
      data = data.filter(i => moment(i.created_at).isSameOrBefore(moment(filter.end_at)));
    }
    return data;
  }
}
