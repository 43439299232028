import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, NgModule, ErrorHandler, Injectable, LOCALE_ID } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from "./app-routing.module";
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi, withXsrfConfiguration } from "@angular/common/http";
import { ToolbarComponent } from './layout/toolbar/toolbar.component';
import {
    SidenavAdditionalServicesComponent
} from './layout/sidenav-additional-services/sidenav-additional-services.component';
import { SidenavProfileComponent } from './layout/sidenav-profile/sidenav-profile.component';
import { FooterComponent } from './layout/footer/footer.component';
import { ErrorNotFoundComponent } from './error-not-found/error-not-found.component';
import { ErrorComponent } from './error/error.component';
import { SiteLayoutComponent } from './layout/site-layout/site-layout.component';
import { MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MessageLayoutComponent } from './layout/message-layout/message-layout.component';
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { LoginLayoutComponent } from './layout/login-layout/login-layout.component';
import { LoginModule } from "./login/login.module";
import { RouterModule, Router } from "@angular/router";
import { DashboardLayoutComponent } from './layout/dashboard-layout/dashboard-layout.component';
import {
    SuppressMobileMatCardLayoutComponent
} from './layout/supress-mobile-mat-card-layout/suppress-mobile-mat-card-layout.component';
import { API_BASE, API_BASE_URL, API_VERSION, OPENWEATHER_API_KEY, SharedModule } from "./shared/shared.module";
import { environment } from "../environments/environment";
import { AuthInterceptor } from "./shared/auth.interceptor";
import { ErrorInterceptor } from "./shared/error.interceptor";
import { ToastrComponent } from "./shared/toastr/toastr.component";
import { ToastrModule } from "ngx-toastr";
import { UserService } from "./shared/services/user.service";
import { AuthService } from "./shared/services/auth.service";
import { RegistrationService } from "./login/registration.service";
import { CreditCardValidator, EmailValidator } from "./shared/validators/pq.validators";
import { PaymentMethodService } from "./tenant/payment/payment-method/payment-method.service";
import {
    PaymentHistoryFilterService
} from "./tenant/payment/payment-history/table-filter/payment-history-filter.service";
import { DataSourceService } from "./shared/rest/data-source.service";
import { MessageService } from "./tenant/message/message.service";
import { MomentModule } from "ngx-moment";
import { PaymentHistoryService } from "./tenant/payment/payment-history/payment-history.service";
import { PaymentScheduleService } from "./tenant/payment/payment-schedule/payment-schedule.service";
import { PropertySelectedGuard } from "./shared/guards/property-selected.guard";
import { CustomisationService } from "./shared/services/customisation.service";
import { take } from "rxjs/operators";
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ViewLayoutComponent } from './layout/view-layout/view-layout.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { CalendarModule, DateAdapter, MOMENT } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/moment";
import moment from "moment";
import { UpcomingEventService } from './tenant/upcoming-event/upcoming-event.service';
import { AppVersionUpdatePromptComponent } from './shared/components/app-version-update-prompt/app-version-update-prompt.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { appReducer } from './store/app.reducers';
import { AppEffects } from './store/app.effects';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ConfirmRedirectComponent } from "./layout/sidenav-profile/confirm-redirect/confirm-redirect.component";
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { LocaleId } from './locale.providor';
import { LocalizationService } from './shared/services/localization.service';


@Injectable()
export class PqHammerConfig extends HammerGestureConfig {
    overrides = <any>{
        'tap': { enabled: false },
        'pinch': { enabled: false },
        'rotate': { enabled: false }
    }
}

export function initApp(customisationService: CustomisationService) {
    return () => {
        return customisationService.config.pipe(take(1));
    };
}

export function momentFactory() {
    return moment;
}

registerLocaleData(localeEn);
registerLocaleData(localeFr);

@NgModule({
    declarations: [
        AppComponent,
        ToolbarComponent,
        SidenavAdditionalServicesComponent,
        SidenavProfileComponent,
        ConfirmRedirectComponent,
        FooterComponent,
        ErrorNotFoundComponent,
        ErrorComponent,
        SiteLayoutComponent,
        MessageLayoutComponent,
        LoginLayoutComponent,
        DashboardLayoutComponent,
        SuppressMobileMatCardLayoutComponent,
        ToastrComponent,
        PrivacyPolicyComponent,
        ViewLayoutComponent,
        TermsOfServiceComponent,
        AppVersionUpdatePromptComponent,
    ],
    exports: [
        NgxMaskDirective,
        NgxMaskPipe,
        MatMomentDateModule
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        RouterModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: { provide: TranslateLoader, useClass: TranslateHttpLoader, deps: [HttpClient] }
        }),
        MatMomentDateModule,
        ToastrModule.forRoot({
            toastComponent: ToastrComponent
        }),
        HammerModule,
        NgxMaskDirective,
        NgxMaskPipe,
        MomentModule.forRoot({
            relativeTimeThresholdOptions: {
                'm': 59
            }
        }),
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
            deps: [MOMENT, MAT_DATE_LOCALE],
        }),
        SharedModule,
        LoginModule,
        StoreModule.forRoot({
            app: appReducer
        }),
        EffectsModule.forRoot([AppEffects])], providers: [
            {
                provide: APP_INITIALIZER,
                useFactory: initApp,
                multi: true,
                deps: [CustomisationService]
            },
            { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
            { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline', floatLabel: 'never' } },
            { provide: HAMMER_GESTURE_CONFIG, useClass: PqHammerConfig },
            { provide: API_BASE_URL, useValue: environment.apiBaseUrl },
            { provide: API_VERSION, useValue: environment.apiVersion },
            { provide: API_BASE, useValue: environment.apiBase },
            { provide: OPENWEATHER_API_KEY, useValue: environment.openWeatherApiKey },
            { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
            { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
            { provide: DEFAULT_CURRENCY_CODE, useValue: 'USD' },
            { provide: MOMENT, useFactory: momentFactory },
            {
                provide: LOCALE_ID,
                useClass: LocaleId,
                deps: [LocalizationService],
            },
            {
                provide: MAT_DATE_LOCALE,
                useClass: LocaleId,
                deps: [LocalizationService],
            },
            AuthService,
            PropertySelectedGuard,
            UserService,
            PaymentHistoryService,
            {
                provide: 'paymentHistoryDataSource',
                useClass: DataSourceService,
                deps: [PaymentHistoryService]
            },
            PaymentScheduleService,
            {
                provide: 'paymentScheduleDataSource',
                useClass: DataSourceService,
                deps: [PaymentScheduleService]
            },
            {
                provide: RegistrationService,
                useClass: RegistrationService,
                multi: false
            },
            {
                provide: EmailValidator,
                deps: [RegistrationService]
            },
            PaymentMethodService,
            {
                provide: 'paymentMethodDataSource',
                useClass: DataSourceService,
                deps: [PaymentMethodService],
            },
            PaymentHistoryFilterService,
            {
                provide: CreditCardValidator,
                deps: [PaymentMethodService]
            },
            {
                provide: 'messagesDataSource',
                useClass: DataSourceService,
                deps: [MessageService]
            },
            {
                provide: 'upcomingEventDataSource',
                useClass: DataSourceService,
                deps: [UpcomingEventService],
            },
            // {
            //     provide: ErrorHandler,
            //     useValue: Sentry.createErrorHandler({
            //         showDialog: false,
            //     }),
            // },
            // {
            //     provide: Sentry.TraceService,
            //     deps: [Router],
            // },
            provideHttpClient(withInterceptorsFromDi(), withXsrfConfiguration({
                cookieName: undefined,
                headerName: undefined
            })),
        ]
})
export class AppModule {
}
